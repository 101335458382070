<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Add New Gift
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="form-group full-width">
            <label for="fieldTitle">
              Title
            </label>
            <input type="text" class="form-control" id="fieldTitle">
          </div>
          <div class="form-group full-width mt-4">
            <label for="fieldPrice">
              Price
            </label>
            <input type="text" class="form-control" id="fieldPrice">
          </div>
          <div class="form-group full-width mt-4">
            <label for="fieldImage">
              Image
            </label>
            <file-field :files="files" id="fieldImage" />
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn bg-green">
          Save
        </button>
        <button class="btn btn-cancel ms-2" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";
export default {
  name: 'AddNewGiftModal',
  components: {FileField},
  data() {
    return {
      files: []
    }
  }
}
</script>
