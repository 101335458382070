<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          Edit Gift
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0">
          <div class="form-group full-width">
            <label for="fieldTitle">
              Title
            </label>
            <input type="text" class="form-control" id="fieldTitle">
          </div>
          <div class="form-group full-width mt-4">
            <label for="fieldPrice">
              Price
            </label>
            <input type="text" class="form-control" id="fieldPrice">
          </div>
          <div class="form-group full-width mt-4">
            <label for="fieldImage">
              Image
            </label>
            <file-field :files="files" id="fieldImage" />
          </div>
          <div class="col-12 ps-0 mt-3">
            <div class="attach-file">
              <div class="">
                <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                <span class="file-name">File.png</span>
              </div>
              <button>
                <img src="@/assets/icons/icon-preview.svg" alt="Preview">
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
          <button class="btn bg-green">
            Save
          </button>
          <button class="btn btn-cancel ms-2" @click="$emit('close')">
            Cancel
          </button>
        </div>
        <button class="btn btn-control">
          <img src="@/assets/icons/icon-delete.svg" alt="Delete">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";
export default {
  name: 'EditGiftModal',
  components: {FileField},
  data() {
    return {
      files: []
    }
  }
}
</script>
