<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">Gifts</h3>
      </div>
      <button class="btn btn-primary" @click="modals.addNewGift = true">
        Add New Gifts
      </button>
    </div>
    <div class="page-content">
      <div class="table">
        <table class="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Price</th>
              <th>Created on</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Premium Godiva + card + CMC packaging + shipping + fulfillment</td>
              <td>$47.50</td>
              <td>Jul, 13 2020</td>
              <td>
                <div class="btn-modal" name="gift">
                  <img src="@/assets/icons/icon-more.svg" alt="More button" class="icon-more">
                </div>
                <tippy  boundary="window"
                        interactive="true"
                        trigger="click"
                        class="table-menu"
                        zIndex="1039"
                        animateFill="false"
                        placement="bottom-end"
                        theme="light"
                        to="gift">
                  <div class="table-menu-block">
                    <ul>
                      <li class="action-label">
                        Actions
                      </li>
                      <li class="cursor-pointer" @click="modals.editGiftModal = true">
                        <a href="#">
                          <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
                          Edit
                        </a>
                      </li>
                      <li class="cursor-pointer">
                        <a href="#">
                          <img src="@/assets/icons/icon-preview.svg" alt="Preview">
                          Preview
                        </a>
                      </li>
                      <li class="cursor-pointer">
                        <a href="#">
                          <img src="@/assets/icons/icon-delete.svg" alt="Delete">
                          Delete
                        </a>
                      </li>
                    </ul>
                  </div>
                </tippy>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-new-gift-modal v-if="modals.addNewGift === true"
                          @close="modals.addNewGift = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-gift-modal v-if="modals.editGiftModal === true"
                       @close="modals.editGiftModal = false" />
    </transition>

  </div>
</template>

<script>
import AddNewGiftModal from "./modals/AddNewGiftModal";
import EditGiftModal from "./modals/EditGiftModal";
export default {
  name: 'GiftManage',
  components: {EditGiftModal, AddNewGiftModal},
  data() {
    return {
      modals: {
        addNewGift: false,
        editGiftModal: false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
